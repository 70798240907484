<template>
  <AContainer
    class="b-programs has-text-centered is-fluid is-paddingless mb-6 p-3"
    id="participating-programs"
  >
    <ASectionTitle>
      <template v-slot:icon>
        <img
          src="../../assets/round-heart.svg"
          alt="Ícone sobre programa BemPerto®"
        />
      </template>
      <template v-slot:title>
        <span class="text-programa-participantes">
          Programas <br />
          participantes
        </span>
      </template>
    </ASectionTitle>
    <MTabs
      class="m-programs-tabs"
      :class="activeTab"
      :activeTab="activeTab"
      :tabs="tabs"
    />
    <div
      v-if="activeTab === 'osteoporose'"
      class="m-program-content"
      id="bemperto-osteoporose"
    >
      <div
        class="m-program-content__img"
        style="border-radius: 6px !important;"
      >
        <img
          src="../../assets/osteoporose.png"
          alt="Programa BemPerto osteoporose"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Osteoporose</h3>
        <p>
          A osteoporose afeta aproximadamente 500 milhões de pessoas em todo
          mundo.<sup>1</sup>
        </p>
        <p>
          O BemPerto<sup>®</sup> foi idealizado pela Amgen<sup>®</sup> e vai
          trazer mais esclarecimentos sobre a doença. A ideia do programa é
          proporcionar mais conforto, oferecendo dicas de como conviver melhor
          com a osteoporose e ter uma rotina mais saudável e confortável no dia
          a dia.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/ossos`"
          title="Conheça o Programa BemPerto da Sua Liberdade"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
    <div
      v-if="activeTab === 'cancer-colorretal'"
      class="m-program-content"
      id="bemperto-cancer-colorretal"
    >
      <div class="m-program-content__img">
        <img
          src="../../assets/programa-bemperto-cancer-colorretal.webp"
          alt="Programa BemPerto câncer colorretal"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Câncer colorretal</h3>
        <p>
          O BemPerto<sup>®</sup> é um programa exclusivo da Amgen<sup>®</sup>
          para suporte gratuito aos pacientes em tratamento de câncer colorretal
          metastático (câncer de intestino) em pacientes com um tipo específico
          de tumor conhecido como "Tumor RAS tipo selvagem". Para nós, estar bem
          perto significa acolher os pacientes e cuidadores, tratando-os com
          atenção e oferecendo orientações e suporte de qualidade.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/intestino`"
          title="Conheça o Programa BemPerto do seu amanhã"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
    <div
      v-if="activeTab === 'leucemia-linfoblastica-aguda'"
      class="m-program-content"
      id="bemperto-leucemia-linfoblastica-aguda"
    >
      <div class="m-program-content__img">
        <img
          src="../../assets/programa-bemperto-leucemia-linfoblastica-aguda.png"
          alt="Programa BemPerto Leucemia Linfoblástica Aguda"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Leucemia Linfoblástica Aguda B</h3>
        <p>
          O BemPerto<sup>®</sup> é um programa gratuito para pacientes que estão
          em tratamento para leucemia linfoblástica aguda (LLA B).
        </p>
        <p>
          Um programa que acolhe os pacientes e cuidadores, tratando todos com
          cuidado e muito carinho. Se você é um paciente de LLA B e deseja
          participar do nosso programa, será um prazer poder fazer parte da sua
          jornada de tratamento.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/medulaossea`"
          title="Conheça o Programa BemPerto de Quem Você Ama"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
    <div
      v-if="activeTab === 'doenca-autoimune'"
      class="m-program-content"
      id="bemperto-doenca-autoimune"
    >
      <div class="m-program-content__img">
        <img
          src="../../assets/programa-bemperto-doenca-autoimune.webp"
          alt="Programa BemPerto Doenças autoimunes"
        />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Doenças autoimunes</h3>
        <p>
          O BemPerto<sup>®</sup> é um programa da Amgen<sup>®</sup> destinado
          aos pacientes em tratamento de doenças autoimunes. Para nós, estar bem
          perto significa apoiar médicos, pacientes e cuidadores, proporcionando
          atenção, orientação e suporte de qualidade, buscando o melhor
          resultado durante o tratamento de cada doença.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/autoimunidade`"
          title="Conheça o Programa BemPerto do seu bem-estar"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
    <!-- <div
      v-if="activeTab === 'pulmao'"
      class="m-program-content"
      id="bemperto-pulmao"
    >
      <div class="m-program-content__img">
        <img src="../../assets/pulmao.webp" alt="Programa BemPerto Pulmão" />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Pulmão</h3>
        <p>
          Bit, abor sum idelitis sae et, nobit, sam ea nam sequam quia dem
          exercimi, velis magnam et, et voluptas pa qui sequia pedictu
          sciendebis quas eum harum volupis ilitistis es atur aut laborrovit
          officium im voluptatem dem nihicta verro essinimus alit, aut volo que
          conseque vende dolorum re vendipis di occab intia santiam, officiae
          minihil istrum delit acepel ipsum quis quaspid enimusda volorem
          experfe ribust milit ad quas aspersp erehentem haria sae nonsedis eum
          et que nonsequod ut ut litatias imporruptat mo que es es molum
          nonsequamus et ma vid et doloria conecep udiaspi dellam aditatae. Eri
          nis et late pro volor a adicto erum fuga.
        </p>
        <AButton
          class="p-4"
          component="external"
          :href="`${host}/pulmao`"
          title="Conheça o Programa BemPerto do seu bem-estar"
        >
          Conheça
        </AButton>
      </AContent>
    </div> -->
    <div
      v-if="activeTab === 'raras'"
      class="m-program-content"
      id="bemperto-raras"
    >
      <div class="m-program-content__img">
        <!-- Switch image later -->
        <img src="../../assets/raras.webp" alt="Programa BemPerto Raras" />
      </div>
      <AContent class="m-program-content__desc mr-3 ml-3 pb-6 pl-5 pr-5 pt-6">
        <h3>Raras</h3>
        <p>
          Estamos aqui para caminhar com você nessa jornada de tratamento.
          Sabemos que tratar uma condição Rara como a NMOSD pode ser desafiador,
          mas você não está sozinho. Nossa equipe especializada está pronta para
          oferecer um suporte personalizado, com informações e conteúdos
          adeptados às suas necessidades.
        </p>
        <AButton
          class="p-4"
          component="external"
          @click.prevent="handleRarasRedirect"
          title="Conheça o Programa BemPerto do seu bem-estar"
        >
          Conheça
        </AButton>
      </AContent>
    </div>
    <MRedirectPopup
      :is-visible="showRedirectPopup"
      redirect-text="Programa BemPerto Raras"
    />
  </AContainer>
</template>

<script>
import { ref } from "vue";
import useTabHandler from "@/composables/useTabHandler";
import AButton from "@/components/atoms/Button.vue";
import AContainer from "@/components/atoms/Container.vue";
import AContent from "@/components/atoms/Content.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import MTabs from "@/components/molecules/Tabs.vue";
import MRedirectPopup from "@/components/molecules/RedirectPopup.vue";

export default {
  components: {
    AButton,
    AContainer,
    AContent,
    ASectionTitle,
    MTabs,
    MRedirectPopup
  },
  name: "OParticipatingPrograms",
  setup() {
    const host = `//${document.location.hostname}`;
    const activeTab = ref("osteoporose");
    const tabs = [
      {
        id: "osteoporose",
        title: "Ossos",
        action: () => useTabHandler("osteoporose", activeTab, onChangeTab)
      },
      {
        id: "cancer-colorretal",
        title: "Intestino",
        action: () => useTabHandler("cancer-colorretal", activeTab, onChangeTab)
      },
      {
        id: "leucemia-linfoblastica-aguda",
        title: "Medula Óssea",
        action: () =>
          useTabHandler("leucemia-linfoblastica-aguda", activeTab, onChangeTab)
      },
      {
        id: "doenca-autoimune",
        title: "Autoimunidade",
        action: () => useTabHandler("doenca-autoimune", activeTab, onChangeTab)
      },
      // {
      //   id: "pulmao",
      //   title: "Pulmão",
      //   action: () => useTabHandler("pulmao", activeTab, onChangeTab)
      // },
      {
        id: "raras",
        title: "Raras",
        action: () => useTabHandler("raras", activeTab, onChangeTab)
      }
    ];

    const showRedirectPopup = ref(false);

    const handleRarasRedirect = () => {
      showRedirectPopup.value = true;
      setTimeout(() => {
        window.open("https://programaaoseulado.com.br/", "_blank");
        showRedirectPopup.value = false;
      }, 4000);
    };

    function onChangeTab(tabName) {
      activeTab.value = tabName;
    }

    return {
      activeTab,
      host,
      tabs,
      showRedirectPopup,
      handleRarasRedirect
    };
  }
};
</script>

<style lang="scss">
.m-programs-tabs {
  border-radius: 5px;
  box-shadow: 0 12px 20px#00000053;
  max-width: 90%;
  overflow-x: auto;
  position: relative;
  top: 18px;
  z-index: 4;

  &.osteoporose {
    background-color: #78bbef;
    font-weight: 600;

    li {
      &.is-active {
        a {
          color: #78bbef;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #78bbef !important;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #78bbef 100%
            );
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #78bbef;
          }

          .a-button {
            background-color: #78bbef;

            &:hover {
              color: white;
            }
          }
        }
      }
    }
  }

  &.cancer-colorretal {
    background-color: #78bbef;

    li {
      &.is-active {
        a {
          color: #78bbef;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #78bbef;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #78bbef 100%
            ) !important;
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #78bbef;
          }

          .a-button {
            background-color: #78bbef;

            &:hover {
              color: #78bbef;
            }
          }
        }
      }
    }
  }

  &.leucemia-linfoblastica-aguda {
    background-color: #78bbef;

    li {
      &.is-active {
        a {
          color: #78bbef;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #78bbef;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #78bbef 100%
            ) !important;
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #78bbef;
          }

          .a-button {
            background-color: #78bbef;

            &:hover {
              color: #78bbef;
            }
          }
        }
      }
    }
  }

  &.doenca-autoimune {
    background-color: #78bbef;

    li {
      &.is-active {
        a {
          color: #78bbef;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #78bbef;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #78bbef 100%
            ) !important;
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #78bbef;
          }

          .a-button {
            background-color: #78bbef;

            &:hover {
              color: #78bbef;
            }
          }
        }
      }
    }
  }

  &.pulmao {
    background-color: #78bbef;

    li {
      &.is-active {
        a {
          color: #78bbef;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #78bbef;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #78bbef 100%
            ) !important;
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #78bbef;
          }

          .a-button {
            background-color: #78bbef;

            &:hover {
              color: #78bbef;
            }
          }
        }
      }
    }
  }

  &.raras {
    background-color: #78bbef;

    li {
      &.is-active {
        a {
          color: #78bbef;
        }
      }
    }

    & + .m-program-content {
      .m-program-content {
        &__img {
          border: 3px solid #78bbef;

          &::before {
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 40%,
              #78bbef 100%
            ) !important;
          }
        }

        &__desc {
          *:not(.a-button) {
            color: #78bbef;
          }

          .a-button {
            background-color: #78bbef;

            &:hover {
              color: #78bbef;
            }
          }
        }
      }
    }
  }

  a {
    color: $white;
    padding: 1.2rem;
    font-size: pxToEm(12px);
  }

  .m-tabs {
    &__spot {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 6px 12px#00000053;
      height: calc(100% - 8px);
      left: 4px;
      position: absolute;
      top: 50%;
      transition: left 0.5s ease-in-out;
      transform: translateY(-50%);
    }
  }
}

.m-program-content {
  position: relative;

  &__img {
    background-color: white;
    border-radius: 6px;
    border-style: solid;
    border-width: 3px;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;

    &::before {
      @extend %position-absolute;
      @extend %pseudo-el;
      @extend %zero-position;
      display: block;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    img {
      @extend %position-absolute;
      @extend %zero-position;
      width: 100%;
    }
  }

  &__desc {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.6643032212885154) 0%,
      rgba(255, 255, 255, 0.8379726890756303) 15%,
      rgba(255, 255, 255, 1) 36%,
      rgba(255, 255, 255, 1) 100%
    );
    border-radius: 5px;
    box-shadow: 0 14px 32px#00000027;
    position: relative;
    top: -40px;
    z-index: 3;
  }

  .a-button {
    @extend %position-absolute;
    @extend %translate-x-center;
    bottom: -24px;
    color: $white;
    display: inline-block;
    text-transform: capitalize !important;
    min-width: 178px;

    &:hover {
      background-color: white !important;
    }
  }
}

@include media(">=tablet") {
  .m-programs-tabs {
    top: 28px;

    a {
      padding: 1.4rem;
    }
  }

  .m-program-content {
    &__desc {
      top: -100px;
    }
  }
}

@include media(">=desktop") {
  .m-programs-tabs {
    top: 38px;

    a {
      font-size: pxToEm(16px);
      padding: 1.6rem;
    }

    &.osteoporose {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 75%
              );
            }
          }
        }
      }
    }

    &.cancer-colorretal {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 75%
              );
            }
          }
        }
      }
    }

    &.leucemia-linfoblastica-aguda {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 75%
              );
            }
          }
        }
      }
    }

    &.doenca-autoimune {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 75%
              );
            }
          }
        }
      }
    }

    &.pulmao {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 75%
              );
            }
          }
        }
      }
    }

    &.raras {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 75%
              );
            }
          }
        }
      }
    }

    .m-program-content {
      &__desc {
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        width: 45%;
      }
    }
  }

  .m-programs-tabs.osteoporose
    + .m-program-content
    .m-program-content__desc
    .a-button {
    background-color: #78bbef !important;
  }

  .m-programs-tabs.osteoporose
    + .m-program-content
    .m-program-content__desc
    *:not(.a-button) {
    color: #78bbef !important;
  }

  .m-programs-tabs.osteoporose + .m-program-content .m-program-content__img {
    border-color: #78bbef;
    border-radius: 3px !important;
  }

  .m-programs-tabs.osteoporose
    + .m-program-content
    .m-program-content__desc
    .a-button {
    font-weight: 600 !important;
  }
}

@include media(">=widescreen") {
  .container.b-programs {
    margin-top: -260px;
    max-width: 90% !important;

    .m-section-title {
      display: none;
    }
  }

  .m-programs-tabs {
    max-width: 96%;

    &.osteoporose {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #78bbef 54%
              );
            }
          }
        }
      }
    }

    &.cancer-colorretal {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #78bbef 54%
              );
            }
          }
        }
      }
    }

    &.leucemia-linfoblastica-aguda {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 50%,
                #78bbef 66%
              );
            }
          }
        }
      }
    }

    &.doenca-autoimune {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #78bbef 54%
              );
            }
          }
        }
      }
    }

    &.pulmao {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #78bbef 54%
              );
            }
          }
        }
      }
    }

    &.raras {
      & + .m-program-content {
        .m-program-content {
          &__img {
            &::before {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 40%,
                #78bbef 54%
              );
            }
          }
        }
      }
    }
  }

  .m-program-content {
    &__img {
      padding-top: 50%;

      img {
        height: 100%;
        width: auto;
      }
    }

    &__desc {
      width: 50%;
    }
  }
}

@include media(">=fullhd") {
  .m-program-content {
    &__img {
      padding-top: 40%;
    }
  }
}

//novos css
.m-programs-tabs.osteoporose {
  background: #78bbef !important;
  box-shadow: 0px 12px 20px #00000033 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}

@media (min-width: 1024px) {
  .m-programs-tabs.osteoporose
    + .m-program-content
    .m-program-content__img::before {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 50%,
      #78bbef 75%
    ) !important;
  }
}

@media (min-width: 1216px) {
  .m-programs-tabs.osteoporose
    + .m-program-content
    .m-program-content__img::before {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 40%,
      #78bbef 54%
    ) !important;
  }
}

.m-program-content__img > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: 0 !important;
}

.m-programs-tabs.osteoporose
  + .m-program-content
  .m-program-content__desc
  .a-button {
  background-color: #78bbef !important;
}

.m-programs-tabs.osteoporose
  + .m-program-content
  .m-program-content__desc
  *:not(.a-button) {
  color: #78bbef !important;
}

.m-programs-tabs.osteoporose + .m-program-content .m-program-content__img {
  border-color: #78bbef;
  border-radius: 3px !important;
}

.m-programs-tabs.osteoporose
  + .m-program-content
  .m-program-content__desc
  .a-button {
  font-weight: 600 !important;
}
@media (min-width: 1500px) and (max-width: 5000px) {
  .m-program-content {
    display: flex !important;
    flex-direction: column !important;
    height: 690px !important;
  }

  .m-program-content__img {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
  }

  .m-program-content__desc {
    position: absolute !important;
    top: 50% !important;
    right: 0 !important;
    transform: translate(-5%, -35%) !important;
  }
}
@media (min-width: 1360px) and (max-width: 1500px) {
  .m-program-content {
    display: flex !important;
    flex-direction: column !important;
    height: 590px !important;
  }

  .m-program-content__img {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
  }

  .m-program-content__desc {
    position: absolute !important;
    top: 38% !important;
    right: 0 !important;
    transform: translate(-5%, -35%) !important;
  }
}
@media (min-width: 1220px) and (max-width: 1360px) {
  .m-program-content {
    display: flex !important;
    flex-direction: column !important;
    height: 565px !important;
  }

  .m-program-content__img {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
  }

  .m-program-content__desc {
    position: absolute !important;
    top: 38% !important;
    right: 0 !important;
    transform: translate(-5%, -35%) !important;
  }
}

@media (min-width: 500px) and (max-width: 1220px) {
  .m-program-content {
    display: flex !important;
    flex-direction: column !important;
    height: 650px !important;
  }

  .m-program-content__img {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
  }

  .m-program-content__desc {
    position: absolute !important;
    top: 30vh !important;
    right: 0 !important;
    transform: translate(0%, 0%) !important;
  }
}
@media (max-width: 500px) {
  .m-program-content {
    display: flex !important;
    flex-direction: column !important;
    height: 690px !important;
  }

  .m-program-content__img {
    width: 100% !important;
    height: auto !important;
    position: absolute !important;
  }

  .m-program-content__desc {
    position: absolute !important;
    top: 20vh !important;
    right: 0 !important;
    transform: translate(0%, 0%) !important;
  }
}
</style>
