<template>
  <ASection class="main-section pl-0 pr-0 pt-3" id="main-section">
    <main class="main" id="main">
      <div>
        <OAboutBemPerto />
      </div>
      <OParticipatingPrograms />
      <OAboutAmgen />
      <OFAQ />
      <NewContact />
    </main>
  </ASection>
</template>

<script>
import ASection from "@/components/atoms/Section.vue";
import OAboutAmgen from "@/components/organisms/AboutAmgen.vue";
import OAboutBemPerto from "@/components/organisms/AboutBemPerto.vue";
import OFAQ from "@/components/organisms/FAQ.vue";
import OParticipatingPrograms from "@/components/organisms/ParticipatingPrograms.vue";
import NewContact from "@/components/molecules/NewContact.vue";
export default {
  components: {
    OAboutAmgen,
    ASection,
    OAboutBemPerto,
    OFAQ,
    OParticipatingPrograms,
    NewContact
  },
  name: "OMainAbout"
};
</script>

<style lang="scss" scoped>
.main {
  margin-top: -46px;

  @include media(">=fullhd") {
    margin-top: -56px;
  }
}
</style>
